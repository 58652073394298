import React, {Component} from 'react';
import styles from "./styles";
import Api from "../services/api";

class ListAssignmentContainer extends Component {

    viewAssignmentInfo = (id, name) => (e) => {
        Api.getMissing(id).then(response => {
            if (response.status === 200) {
                if (response.data.length === 0) {
                    const confirmMissing = window.confirm("Assignment " +
                        name + ' with id ' + id + ' is not missing shipments.\n');
                } else {
                    const confirmMissing = window.confirm("Assignment " +
                        name + ' with id ' + id + ' is missing ' + response.data.length + ' shipments :\n' + response.data);
                }
            }
        });
    }

    render() {
        const {assignments, damagedList} = this.props;
        
        if (!assignments) return null;

        var style = styles.smallestAssignment;
        var titleStyle = styles.title;
        var countStyle = styles.count;

        if (assignments.length > 153) {
            style = styles.smallestAssignment;
            titleStyle = {
                ...titleStyle,
                fontSize: '20px'
            };
            countStyle = {
                ...countStyle,
                fontSize: '20px'
            }
        } else if (assignments.length > 84) {
            style = styles.smallAssignment;
            titleStyle = {
                ...titleStyle,
                fontSize: '25px'
            };
            countStyle = {
                ...countStyle,
                fontSize: '25px'
            }
        } else {
            style = styles.largeAssignment;
            titleStyle = {
                ...titleStyle,
                fontSize: '32px'
            };
            countStyle = {
                ...countStyle,
                fontSize: '25px'
            }
        }

        return (<div>
            {assignments && assignments.map(a => {
                const scanned = a.shipments.filter(s => !!s.inbound_status && s.inbound_status != "MISSING").length;
                var fill = Math.round(parseInt(scanned) / parseInt(a.shipments.length) * 100);
                var remainder = (100 - fill);

                var hasDamaged = !!a.shipments.find(s => s.inbound_status && s.inbound_status.includes("DAMAGED"));
                var hasMissing = !!a.shipments.find(s => s.inbound_status && s.inbound_status.includes("MISSING" &&  Date.now() < s.dropoff_earliest_ts));

                var backgroundImage = "";

                if (scanned === a.shipments.length) {
                    if (hasDamaged) {
                        backgroundImage = `linear-gradient(to bottom, white ${remainder}%, #A3A3A3 ${remainder}%, #A3A3A3 ${fill}%)`;
                    } else {
                        backgroundImage = `linear-gradient(to bottom, white ${remainder}%, #4ABC4E ${remainder}%, #4ABC4E ${fill}%)`;
                    }
                } else {                    
                    if (hasDamaged && hasMissing) {
                        backgroundImage = `linear-gradient(to bottom, #FF0000 ${remainder}%, #D0021B ${remainder}%, #D0021B ${fill}%)`;
                    } else if (hasDamaged) {
                        backgroundImage = `linear-gradient(to bottom, white ${remainder}%, #D0021B ${remainder}%, #D0021B ${fill}%)`;
                    } else if (hasMissing) {
                        backgroundImage = `linear-gradient(to bottom, #FF0000 ${remainder}%, rgba(136, 127, 255, 0.5) ${remainder}%, rgba(136, 127, 255, 0.5) ${fill}%)`;
                    } else {
                        backgroundImage = `linear-gradient(to bottom, white ${remainder}%, rgba(136, 127, 255, 0.5) ${remainder}%, rgba(136, 127, 255, 0.5) ${fill}%)`;
                    }
                }
                
                var parts = a.assignment_prefix.split(/[-_]+/);
                const label = parts[parts.length-1];
                
                return <button key={`a-${a.id}`} style={{...style, backgroundImage}} onClick={this.viewAssignmentInfo(a.id, parts)}>
                    <div style={titleStyle}>{label}</div>
                    <div style={countStyle}>{scanned}/{a.shipments.length}</div>
                </button>
            })}
        </div>);
    }
}

export default ListAssignmentContainer;