//Local
// export const REACT_APP_DATAORCH = "http://localhost:8088";
// export const REACT_APP_WEBSOCKET_ROOT = "ws://localhost:8078/ws/topics";
// export const REACT_APP_OAUTH_URL = "http://localhost:5001";

//Prod use local.axlehire.com:3000 for testing
export const REACT_APP_DATAORCH = process.env.REACT_APP_DATAORCH;
export const REACT_APP_WEBSOCKET_ROOT = process.env.REACT_APP_WEBSOCKET_ROOT;
export const REACT_APP_OAUTH_URL = process.env.REACT_APP_OAUTH_URL;

//Staging
// export const REACT_APP_DATAORCH = "https://dataorch.staging.axlehire.com";
// export const REACT_APP_WEBSOCKET_ROOT = "wss://ws.staging.axlehire.com/ws/topics";
// export const REACT_APP_OAUTH_URL = "https://oauth.staging.axlehire.com";

//Key Values
// export const REACT_APP_DATAORCH = "__ENV__REACT_APP_DATAORCH__END__";
// export const REACT_APP_OAUTH_URL = "__ENV__REACT_APP_OAUTH_URL__END__";
// export const REACT_APP_WEBSOCKET_ROOT = "__ENV__REACT_APP_WEBSOCKET_ROOT__END__";
