import React, {Component} from 'react';

class Footer extends Component {
    render() {
        const {assignments} = this.props;
        var scannedShipments = [];
        var unscannedShipments = [];
        if (assignments) {
            scannedShipments = assignments.flatMap(a => a.shipments.filter(s => !!s.inbound_scan_ts)).sort((s1, s2) => s1.inbound_scan_ts >= s2.inbound_scan_ts ? -1: 1);
            unscannedShipments = assignments.flatMap(a => a.shipments.filter(s => !s.inbound_status)).sort((s1, s2) => s1.dropoff_earliest_ts >= s2.dropoff_earliest_ts ? -1: 1);
        }

        // get latest 50 scanned shipments
        return (
            <div className="bottom-container">
                <div className="scanned-container">
                    <div className="bottom-fill">Scanned:</div>
                    <div className="bottom-list" id="recentlyScanned">
                        {scannedShipments && scannedShipments.length > 0 && scannedShipments.slice(0, 50).map((s, i) => <div key={`shipment-${s.id}`} className="recently-scanned">
                            {i !== 0 && s.label}                            
                            {i === 0 && <strong>{s.label}</strong>}
                            {i < (scannedShipments.length - 1) && ","}
                        </div>)}
                    </div>
                </div>
                <div className="scanned-container">
                    <div className="missing-fill">Unscanned/missing:</div>
                    <div className="bottom-list" id="stillMissing">
                        {unscannedShipments && unscannedShipments.length > 0 && unscannedShipments.slice(0, 50).map((s, i) => <div key={`shipment-${s.id}`} className="recently-scanned">
                            {i !== 0 && s.label}
                            {i === 0 && <strong>{s.label}</strong>}
                            {i < (unscannedShipments.length - 1) && ","}
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;