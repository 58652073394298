import React, {Component} from 'react';

class RightSidebar extends Component {
    render() {

        const {assignments} = this.props.assignments;
        const clientShipments = this.props.assignments.clientShipments;
        var completedAssignments = !assignments ? []: assignments.filter(a => a.shipments.filter(s => !s.inbound_status).length === 0);

        completedAssignments.sort((a, b) => {
            var aScanTs = Math.max(...a.shipments.filter(s => s.inbound_scan_ts).map(s => s.inbound_scan_ts));
            var bScanTs = Math.max(...b.shipments.filter(s => s.inbound_scan_ts).map(s => s.inbound_scan_ts));
            return aScanTs > bScanTs ? -1 : 1
        })

        const shipments = !assignments ? [] : assignments.flatMap(a => a.shipments);
        const scannedShipments = shipments.filter(s => s.inbound_status && s.inbound_status != 'READY');
        if (clientShipments == 0) {
            var unexpectedCount = ''
        } else {
            if ((shipments.length - clientShipments) >= 0){
                var unexpectedCount = shipments.length - clientShipments
            } else {
                var unexpectedCount = ''
            }
        }


        return (
            <div className={`right-inner-fill`}>
                <div className="shipments-fill">
                    <div className="scanned-text">SCANNED SHIPMENTS</div>
                    <div className="scanned-shipments">{`${scannedShipments.length} / ${shipments.length}`}</div>
                    <div className="unexpected-text">+ {unexpectedCount} UNEXPECTED SHIPMENTS</div>
                </div>
                <div className="completed-fill">
                    <div className="completed-fill-inner">
                        <div className="completed-assignment-text">COMPLETED ASSIGNMENTS</div>
                        <div className="completed-assignments"><span id="completedNumber">{completedAssignments.length}</span>/<span
                            id="totalAssignments">{assignments ? assignments.length: ''}</span></div>
                        <div id="completedAssignments">
                            {completedAssignments && completedAssignments.length > 0 && completedAssignments.map(a => <div key={`assingment-${a.id}`} className="completed-item">
                                <div className="item">{a.assignment_prefix}</div>
                                <div  className="item"/>
                                <div className="item">{a.shipments.length}</div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RightSidebar;