import React, {Component} from 'react';
import styles from "./styles";
import Api from "../services/api";

class ListClientContainer extends Component {

    lockClientRegionAssignments = (region, client) => (e) => {
        console.log('Locking for region ' + region)
        const confirmLock = window.confirm("Are you sure you want to lock for client " + client + " for the " + region + " region?");

        // if (confirmLock) {
        //     console.log('Locked for ' + region + ' for ' + client)
        //     Api.lockRegionClient(region, client)
        // }
        // this.setState({lockSelected: false})
    }

    render() {
        const region = this.props.clients.selectedRegion;
        const {clients} = this.props.clients;
        if (!clients) return null;
        const clientCount = clients.length;

        var clientContainerStyle = styles.clientContainer
        var infoStyle = styles.clientTrackInfo
        var headerStyle = styles.clientTrackHeader
        var itemStyle = styles.clienttrack
        // var tableStyle = styles.tableStyle
        return (<div>
            <div style={infoStyle}> Total Clients: {clientCount}
            </div>
            <div style={clientContainerStyle}>
                <div>
                    <button style={headerStyle}>Client</button>
                    <button style={headerStyle}>Client ID</button>
                    <button style={headerStyle}>Scanned Shipments</button>
                    <button style={headerStyle}>Total Shipments</button>
                    <button style={headerStyle}>Status</button>
                    {/*<button style={headerStyle}>Client Lock</button>*/}
                </div>
                <div>
                    {clients && clients.map(c => {
                        var company = c.company;
                        var id = c.client_id;
                        var scanned = c.scanned;
                        var count = c.count;
                        var status = "";
                        var leftStyle = itemStyle;
                        var rightStyle = itemStyle;

                        //TODO add field to account for damaged/missing
                        if (scanned == count) {
                            status = "DONE"
                            leftStyle = {...itemStyle, borderLeft: 'solid #4FBA54 5px'}
                        } else if (scanned > 0) {
                            status = "IN PROGRESS"
                            leftStyle = {...itemStyle, borderLeft: 'solid #F3A536 5px'}
                        } else {
                            status = "NOT STARTED"
                            leftStyle = {...itemStyle, borderLeft: 'solid #8d8d8d 5px'}
                        }
                        if (c == clients[0]) {
                            leftStyle = {...leftStyle, borderTopLeftRadius: '7px'}
                            rightStyle = {...rightStyle, borderTopRightRadius: '7px'}
                        }
                        if (c == clients[(clients.length - 1)]) {
                            leftStyle = {...leftStyle, borderBottomLeftRadius: '7px'}
                            rightStyle = {...rightStyle, borderBottomRightRadius: '7px'}
                        }
                        return <div>
                            <button style={leftStyle}>{company}</button>
                            <button style={itemStyle}>{id}</button>
                            <button style={itemStyle}>{scanned}</button>
                            <button style={itemStyle}>{count}</button>
                            <button style={rightStyle}>{status}</button>
                            {/*<button onClick={this.lockClientRegionAssignments(region,id)} style={rightStyle}>Lock for {company}</button>*/}
                        </div>
                    })}
                </div>
            </div>


        </div>);
    }
}

export default ListClientContainer;