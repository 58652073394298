import React, {Component} from 'react';
import Link from "react-router-dom/es/Link";
import {Box, Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@material-ui/core";

import {REACT_APP_OAUTH_URL} from '../config';
import Api from "../services/api";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionSelected: false,
            lockSelected: false,
            isLockDisabled: false,
            isUnlockDisabled: false,
            showWarning: false,
        }
    }

    regionRef = React.createRef();
    lockRef = React.createRef();
    userRef = React.createRef();

    componentDidMount() {
        document.addEventListener("mousedown", this.dropdownOutsite);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.dropdownOutsite);
    }

    dropdownOutsite = (event) => {
        if (!this.regionRef.current.contains(event.target)) {
            this.setState({regionSelected: false})
        }
        if (!this.userRef.current.contains(event.target)) {
            this.setState({userSelected: false})
        }
        if (!this.lockRef.current.contains(event.target)) {
            this.setState({lockSelected: false})
        }
    }
    assignmentsView = (region) => (e) => {
        const {assignmentView} = this.props;
        assignmentView(region);
    }
    clientsView = (region) => (e) => {
        const {clientView} = this.props;
        clientView(region);
    }
    dropdownRegion = () => {
        this.setState({regionSelected: !this.state.regionSelected});
    }
    dropdownLock = () => {
        this.setState({lockSelected: !this.state.lockSelected})
    }
    dropdownUser = () => {
        this.setState({userSelected: !this.state.userSelected});
    }
    onChangeRegion = (region) => (e) => {
        const {changeRegion} = this.props;
        changeRegion(region);
        this.setState({regionSelected: false})
    }

    preLockUnlock = (region,action) => (e) => {
        this.setState({showWarning: true});
    }

    closeWarning = () => this.setState({showWarning: false});

    // lockRegionAssignments = (region) => {
    //     if (this.state.isLockDisabled == false) {
    //         const confirmLock = window.confirm("Are you sure you want to lock for ALL clients for the " + region + " region?");
    //         if (confirmLock) {
    //             if (region == 'LAX'){
    //                 Api.lockRegion('SDLAX')
    //             }
    //             this.setState({lockSelected: false})
    //             this.setState({isLockDisabled: true})
    //             Api.lockRegion(region)
    //             setTimeout(this.allowLock, 5000);
    //         }
    //     }
    // }

    // unlockRegionAssignments = (region) => {
    //     if (this.state.isUnlockDisabled == false) {
    //         const confirmLock = window.confirm("Are you sure you want to unlock for ALL clients for the " + region + " region?");
    //         if (confirmLock) {
    //             if (region == 'LAX') {
    //                 Api.unlockRegion('SDLAX')
    //             }
    //             this.setState({lockSelected: false})
    //             this.setState({isUnlockDisabled: true})
    //             Api.unlockRegion(region)
    //             setTimeout(this.allowUnlock, 5000);
    //         }
    //     }
    // }

    allowLock = (e) => {
        this.setState({isLockDisabled: false})
    }

    allowUnlock = (e) => {
        this.setState({isUnlockDisabled: false})
    }

    logout = (e) => {
        window.location = `${REACT_APP_OAUTH_URL}/login?logout=true`;
    }

    render() {
        const {selectedRegion, regions, buttons, user, changeRegion, assignmentView, clientView} = this.props;
        const regionLists = [
            {name: 'SFO', value: 'sfo'},
            {name: 'LAX', value: 'lax'},
            {name: 'SDLAX', value: 'sdlax'},
            {name: 'PHX', value: 'phx'},
            {name: 'PDX', value: 'pdx'},
            {name: 'SEA', value: 'sea'},
            {name: 'DFW', value: 'dfw'},
            {name: 'HOU', value: 'hou'},
            {name: 'JFK', value: 'jfk'},
            {name: 'EWR', value: 'ewr'}
        ];

        const regionName = this.state.regionValue ? this.state.regionValue && regionLists.map(r => r.value === this.state.regionValue && r).filter(r => r) && regionLists.map(r => r.value === this.state.regionValue && r).filter(r => r)[0].name : 'Pick Region';

        return (
            <div className="top-bar">
                <div className="sc-bdVaJa iacpbH"
                     style={{backgroundColor: {r: 255, g: 255, b: 255}, color: {r: 34, g: 34, b: 34}}}>
                    <div className="sc-bwzfXH jGtvNK">
                        {/*Logo*/}
                        <div className="sc-htpNat bnMwsS">
                            <img alt="" src="ah-logo.png" alt="logo" className="sc-EHOje cEqgiF"/>
                            <span className="sc-bxivhb fJMbAJ">Warehouse Display</span>
                            <span id="currentView"></span>
                        </div>

                        <table className="headerTabs">
                            {/*TODO Add these view buttons back and the corresponding functionality*/}
                            <Link to='/'>
                            <tr className="headerBlock" onClick={this.assignmentsView(selectedRegion)}>Shipments</tr>
                            </Link>
                            <Link to='/clients'>
                            <tr className="headerBlock" onClick={this.clientsView(selectedRegion)}>Clients</tr>
                            </Link>
                        </table>

                        {/*Lock button TEMPORARY*/}
                        <div className="lock-select" ref={this.lockRef}>
                            <div className="sc-bZQynM jGHUFq" id="dropdown" onClick={this.dropdownLock}>
                                <img alt="" src="Lock.jpg" title="Lock" width="27" className="sc-htoDjs krSiVs"/>
                                <span className="sc-dnqmqq hizcn">Locking</span>
                                <span className={`arrow${this.state.lockSelected ? ' down' : ''}`}></span>
                            </div>
                            {this.state.lockSelected &&
                            <div className="sc-gZMcBi iRYSpj" id="dropdownLockMenu">
                                <div>
                                    <div id="lockButtons">
                                        <ul>
                                            <li>
                                                <div onClick={this.preLockUnlock(selectedRegion,"lock")} disabled={this.state.isLockDisabled}>LOCK for region {selectedRegion}</div>
                                                {/*<div onClick={this.lockRegionAssignments(selectedRegion)} disabled={this.state.isLockDisabled}>LOCK for region {selectedRegion}</div>*/}
                                            </li>
                                            <li>
                                                {/*<div onClick={this.unlockRegionAssignments(selectedRegion)} disabled={this.state.isUnlockDisabled}>UNLOCK for region {selectedRegion}</div>*/}
                                                <div onClick={this.preLockUnlock(selectedRegion,"unlock")} disabled={this.state.isUnlockDisabled}>UNLOCK for region {selectedRegion}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        {/*Region selector*/}
                        <div className="region-select" ref={this.regionRef}>
                            <div className="sc-bZQynM jGHUFq" id="dropdownRegion" onClick={this.dropdownRegion}>
                                <img alt="" src="globe.png" title="Region" width="27" className="sc-htoDjs krSiVs"/>
                                <span className="sc-dnqmqq hizcn">{selectedRegion}</span>
                                <span className={`arrow${this.state.regionSelected ? ' down' : ''}`}></span>
                            </div>
                            {this.state.regionSelected && <div className="sc-gZMcBi iRYSpj" id="dropdownRegionMenu">
                                <div id="region">
                                    <ul>
                                        {regions.map(r => <li onClick={this.onChangeRegion(r)}>{r}</li>)}
                                    </ul>
                                </div>
                            </div>}
                        </div>

                        {/*User dropdown*/}
                        <div className="sc-ifAKCX YrXYd" ref={this.userRef}>
                            <div className="sc-bZQynM jGHUFq" id="dropdown" onClick={this.dropdownUser}>
                                <img alt="" src="ah-logo.png" title="Client" width="27" className="sc-htoDjs krSiVs"/>
                                <span className="sc-dnqmqq hizcn">User</span>
                                <span className={`arrow${this.state.userSelected ? ' down' : ''}`}></span>
                            </div>
                            {this.state.userSelected && this.props.profile &&
                            <div className="sc-gZMcBi iRYSpj" id="dropdown-menu">
                                <div>
                                    <div id="menuButtons">
                                        <ul>
                                            {this.props.profile.user && this.props.profile.user.name &&
                                            <li>{this.props.profile.user.name}</li>}
                                            <li>
                                                <button onClick={this.logout}>Logout</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

                <Dialog open={this.state.showWarning} onClose={this.closeWarning}>
                    <DialogTitle>Function Moved</DialogTitle>
                    <DialogContent>
                        <Box py={1}>
                            Lock/Unlock function has been moved to <a href="https://inbound.axlehire.com">Inbound App</a>.
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box p={1}>
                            <Button onClick={this.closeWarning} variant="outlined" color="secondary" style={{marginRight: 10}}>
                                Got it
                            </Button>
                            <Button href="https://inbound.axlehire.com" target="_blank" variant="contained" disableElevation color="primary">
                                Take me there
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Header;
