import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';

import Header from './widgets/header';
import Footer from './widgets/footer';
import RightSidebar from './widgets/right';
import ListAssignmentContainer from './containers/assignment';
import ListClientContainer from './containers/clients';

import Api from './services/api';
import Websocket from './services/websocket';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regions: [
                'SFO',
                'LAX',
                'SDLAX',
                'PHX',
                'PDX',
                'SEA',
                'DFW',
                'HOU',
                'JFK',
                'EWR'
            ],
            selectedRegion: 'SFO',
            clientShipments: 0,
            user: null
        };

        this.ws = new Websocket();
    }

    componentDidMount() {
        Api.getUser().then(response => {
            if (response.status === 200) {
                this.setState({user: response.data});
            }
        });

        this.refreshData(null, this.state.selectedRegion);
    }

    refreshData(oldRegion, newRegion) {
        if (oldRegion) {
            this.ws.unsubscribe(`${oldRegion}_SCAN_SHIPMENT`, this.handleScan);
        }

        this.ws.subscribe(`${newRegion}_SCAN_SHIPMENT`, this.handleScan);

        Api.getRegionClients(newRegion).then(response => {
                if (response.status === 200) {
                    var clients = response.data;
                    var clientsCount = 0
                    clients.forEach(c => {
                        clientsCount += c.count

                    })
                    this.setState({clients: clients, selectedRegion: newRegion});
                    this.setState({clientShipments: clientsCount});
                }
            }
        )

        Api.getNextDayAssignments(newRegion).then(response => {
            if (response.status === 200) {
                var assignments = response.data;
                assignments.forEach(a => {
                    // process shipments
                    var arrShipment = a.shipments.split(",");
                    var shipments = arrShipment.map(s => {
                        var arrField = s.split("@");
                        var shipment = {
                            id: parseInt(arrField[0]),
                            client_id: parseInt(arrField[1]),
                            dropoff_earliest_ts: (new Date(arrField[2] + "Z")).getTime(),
                            label: arrField[3] ? arrField[3].substring(1) : '',
                        };

                        if (arrField.length > 4) {
                            shipment['inbound_status'] = arrField[4];
                        }

                        if (arrField.length > 5) {
                            shipment['inbound_scan_ts'] = (new Date(arrField[5] + "Z")).getTime();
                        }

                        return shipment;
                    })

                    a.shipments = shipments;
                })

                this.setState({assignments: assignments, selectedRegion: newRegion});
            }
        });
    }

    changeRegion = (region) => {
        const {selectedRegion} = this.state;
        this.refreshData(selectedRegion, region);
        this.setState({selectedRegion: region});
    }

    handleScan = (msg) => {
        console.log('got websocket message', msg);
        const {assignments} = this.state;

        var shipment = JSON.parse(msg);
        if (shipment.assignment_id) {
            const a = assignments.find(a => a.id === shipment.assignment_id);
            if (a) {
                a.shipments = a.shipments.map(s => {
                    if (s.id === shipment.id) {
                        s.inbound_status = shipment.inbound_status;
                        s.inbound_scan_ts = new Date(shipment.inbound_scan_ts).getTime();
                        s.dropoff_earliest_ts = shipment.dropoff_earliest_ts;
                    }
                    return s;
                });
            }

            this.setState({assignments});
        }
    }

    assignmentView = (region) => {
        Api.getNextDayAssignments(region).then(response => {
            if (response.status === 200) {
                console.log(response.date)
                var assignments = response.data;
                assignments.forEach(a => {
                    // process shipments
                    var arrShipment = a.shipments.split(",");
                    var shipments = arrShipment.map(s => {
                        var arrField = s.split("@");
                        var shipment = {
                            id: parseInt(arrField[0]),
                            client_id: parseInt(arrField[1]),
                            dropoff_earliest_ts: (new Date(arrField[2] + "Z")).getTime(),
                            label: arrField[3] ? arrField[3].substring(1) : '',
                        };

                        if (arrField.length > 4) {
                            shipment['inbound_status'] = arrField[4];
                        }

                        if (arrField.length > 5) {
                            shipment['inbound_scan_ts'] = (new Date(arrField[5] + "Z")).getTime();
                        }

                        return shipment;
                    })

                    a.shipments = shipments;
                })

                this.setState({assignments: assignments, selectedRegion: region});
            }
        });
    }

    clientView = (region) => {
        Api.getRegionClients(region).then(response => {
                if (response.status === 200) {
                    var clients = response.data;
                    this.setState({clients: clients, selectedRegion: region})
                }
            }
        )
    }

    render() {
        const {assignments, clients, regions, selectedRegion, clientShipments, user} = this.state;

        return (
            <Router>
                <div className="App">
                    <Header selectedRegion={selectedRegion} changeRegion={this.changeRegion} regions={regions}
                            profile={this.state.user} assignments={assignments} clients={clients} clientShipments={clientShipments}
                            assignmentView={this.assignmentView} clientView={this.clientView}/>
                    <div className="main">
                        <div className="assignment-background">
                            <Switch>
                                <Route exact path='/'
                                       render={() => <ListAssignmentContainer assignments={assignments}/>}/>
                                <Route exact path='/clients'
                                       render={() => <ListClientContainer clients={{clients, selectedRegion}}/>}/>
                            </Switch>
                        </div>
                        <div className="right-fill">
                            <RightSidebar assignments={{assignments, clientShipments}}/>
                        </div>
                    </div>
                    <Footer assignments={assignments}/>
                </div>
            </Router>
        );
    }
}

export default App;
