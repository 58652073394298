import { create } from 'apisauce'
import * as axios from 'axios';
import {REACT_APP_DATAORCH, REACT_APP_OAUTH_URL} from '../config';

axios.defaults.withCredentials = true;

class Api {
    constructor() {
        this.api = create({
            baseURL: REACT_APP_DATAORCH,
            withCredentials: true
        });

        this.api.addResponseTransform(response => {
            if ((response.status === 401 || response.status === 403) && !response.headers['no-redirect']) {
                window.location.href = `${REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
            }
        });
    }

    // get assignments
    getAssignments(region) {        
        return this.api.get(`assignments/next-day-list/${region}`);
    }

    getDamagedRegion(region) {
        return this.api.get(`assignments/get-damaged/${region}`);
    }

    getRegionClients(region){
        return this.api.get(`assignments/list-clients/${region}`)
    }

    // get assignments
    getNextDayAssignments(region) {
        return this.api.get(`warehouses/next-day/${region}`);
    }

    preLockUnlockRegion(region) {
        return this.api.get(`warehouses/pre-lock-unlock-region/${region}`)
    }

    // lock warehouse
    // lockRegion(region) {
    //     return this.api.get(`warehouses/lock-region/${region}`);
    // }

    // lock warehouse
    // unlockRegion(region) {
    //     return this.api.get(`warehouses/unlock-region/${region}`);
    // }

    // lock warehouse
    lockRegionClient(region, client) {
        return this.api.get(`warehouses/lock-region/${region}/${client}`);
    }

    getRegionClientIds(shipments) {
        return this.api.get(`shipments/lock-region/${shipments}`);
    }

    // get user
    getUser() {        
        return this.api.get(`me`);
    }

    getMissing(id) {
        return this.api.get(`assignments/get-missing-shipments/${id}`);
    }
}

export default new Api();