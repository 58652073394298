export default {
    title: {
        fontFamily: 'AvenirNext',
        fontSize: '15px',
        textAlign: 'center',
        color: '#3b3b3b',
    },
    count: {
        fontFamily: 'AvenirNext',
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'center',
        color: '#3b3b3b',
    },
    smallestAssignment: {
        width: '60px',
        height: '60px',
        fontSize: '16px',
        marginLeft: '7px',
        marginTop: '7px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff'
    },
    smallAssignment: {
        width: '66px',
        height: '66px',
        fontSize: '22px',
        marginLeft: '12px',
        marginTop: '12px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff'
    },
    largeAssignment: {
        width: '100px',
        height: '100px',
        fontSize: '35px',
        marginLeft: '15px',
        marginTop: '15px',
        borderRadius: '5px',
        border: 'none',
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff'
    },

    clientTrackInfo: {
        paddingTop: '40px',
        paddingLeft: '50px',
        width: 'calc (100%)',
        fontSize: '22px',
        backgroundColor: '#F7F6FF',
        border: '0px',
        color: '#2a2444',
        position: 'relative',
        display: 'inline-block',
        whitespace: 'pre-wrap',
        float: 'left',
        verticalAlign: 'top'
    },

    clientContainer: {
        marginLeft: '50px',
        marginRight: '50px',
        paddingTop: '70px'
    },

    clientTrackHeader: {
        paddingTop: '30px',
        paddingBottom: '50px',
        justifyContent: 'center',
        height: '30px',
        width: '16%',
        fontSize: '18px',
        fontWeight: 'bold',
        backgroundColor: '#F7F6FF',
        border: '0px',
        color: '#2a2444',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        whitespace: 'pre-wrap',
        // float: 'left',
        verticalAlign: 'top'
    },

    tableStyle: {
        boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.85)'
    },

    clienttrack: {
        justifyContent: 'center',
        height: '90px',
        width: '16%',
        fontSize: '18px',
        fontWeight: 'bold',
        border: '0px',
        color: '#8d8d8d',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        whitespace: 'pre-wrap',
        // float: 'left',
        verticalAlign: 'top'
    }


}